export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home/Home.vue')
  },
  {
    path: '/MatrixDataset',
    component: () => import('@/pages/article/MatrixDataset.vue')
  },
  {
    path: '/NewSenceDataset',
    component: () => import('@/pages/article/NewSenceDataset.vue')
  },
  {
    path: '/PINDataset',
    component: () => import('@/pages/article/PINDataset.vue')
  },
  {
    path: '/OmniHD-Scenes',
    component: () => import('@/pages/article/OmniHD-Scenes.vue')
  },
]
